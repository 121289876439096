import styled, { css } from "styled-components";
import Icon from "vui/components/Icon";

export const NavRailContainer = styled.div`
  background: rgb(var(--vui-color-surface));
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: fixed;
`;

export const NavRailContent = styled.nav`
  display: flex;
  flex-direction: column;
  margin: var(--vui-spacing-4) 0 0;
  width: var(--vui-spacing-20);

  * + & {
    margin-top: 0;
  }
`;

export const NavRailContentItemLabel = styled.span`
  font-family: var(--vui-typescale-label-medium-font);
  font-size: var(--vui-typescale-label-medium-size);
  font-weight: var(--vui-typescale-label-medium-weight);
  line-height: var(--vui-typescale-label-medium-line-height);
`;

export const NavRailContentItemIcon = styled(Icon)`
  display: block;
  height: var(--vui-spacing-6);
  margin: 0 auto var(--vui-spacing-1);
  position: relative;
  width: var(--vui-spacing-6);

  &::before {
    background: rgb(var(--vui-color-secondary-container));
    border-radius: var(--vui-shape-corner-full);
    content: "";
    height: var(--vui-spacing-8);
    position: absolute;
    opacity: 0;
    transform: translateX(calc(-50% + var(--vui-spacing-3)))
      translateY(calc(-1 * var(--vui-spacing-1)));
    width: var(--vui-spacing-14);
    z-index: -1;
  }
`;

export const NavRailContentItemOuter = styled.div<{ active?: boolean }>`
  display: block;
  margin: 0 auto;
  text-align: center;

  ${({ active }) =>
    active &&
    css`
      ${NavRailContentItemIcon} {
        &::before {
          opacity: 1;
        }
      }
    `}
`;

export const NavRailFooter = styled.div`
  align-items: center;
  display: flex;
  height: 4.125rem;
  justify-content: center;
  margin-bottom: 2.625rem;
  margin-top: auto;
`;

// TODO: Check what the design token would be named for Vidsy brand
export const NavRailHeader = styled.div`
  height: 5rem;
  margin-bottom: 2.5rem;
  margin-top: 2.75rem;
  color: #e3274e;
`;

export const NavRailLink = styled.a<{ $active?: boolean }>`
  color: rgb(var(--vui-color-on-surface-variant));
  display: block;
  margin: 0 var(--vui-spacing-0-5) var(--vui-spacing-3);
  min-height: var(--vui-spacing-14);
  text-decoration: none;

  &:hover {
    color: rgb(var(--vui-color-on-surface));

    ${NavRailContentItemIcon} {
      &::before {
        background: rgba(
          var(--vui-color-on-surface),
          var(--vui-state-layer-hover)
        );
        opacity: 1;
      }
    }
  }

  &:focus {
    color: rgb(var(--vui-color-on-surface));
    outline: none;

    ${NavRailContentItemIcon} {
      &::before {
        background: rgba(
          var(--vui-color-on-surface),
          var(--vui-state-layer-focus)
        );
        opacity: 1;
      }
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      color: rgb(var(--vui-color-on-surface));

      &:hover {
        ${NavRailContentItemIcon} {
          &::before {
            background-color: color-mix(
              in srgb,
              rgb(var(--vui-color-secondary-container)),
              rgb(var(--vui-color-on-surface)) var(--vui-state-layer-hover)
            );
          }
        }
      }

      &:focus {
        ${NavRailContentItemIcon} {
          &::before {
            background-color: color-mix(
              in srgb,
              rgb(var(--vui-color-secondary-container)),
              rgb(var(--vui-color-on-surface)) var(--vui-state-layer-focus)
            );
          }
        }
      }
    `}
`;
