import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import FormikURLInput from "creators/components/forms/FormikURLInput";
import ANALYTICS from "creators/constants/analytics";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import REGEX from "creators/constants/regex";
import VIDEOS from "creators/constants/videos";
import { getStepForCreator } from "creators/hooks/useOnboardingProgressAnalytics/useOnboardingProgressAnalytics";
import { IFormValues } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useCallback, useState } from "react";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import styled from "styled-components";
import {
  Back,
  Heading,
  Next,
  ResponsiveLayout,
  StyledSteppedProgress,
  VideoContainer,
} from "../styles";

export interface IProps {
  creatorExternalID: string;
  initialValues: IFormValues;
  onBack: () => void;
  onNext: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const Portfolio = styled(FormikURLInput)`
  margin-bottom: var(--v-spacing-12);
`;

export const validation = (values: IFormValues): FormikErrors<IFormValues> => {
  const errors: FormikErrors<IFormValues> = {};

  if (!values.portfolio) {
    errors.portfolio = textualize(
      "onboarding.portfolio.error.required",
    ) as string;
  } else if (!REGEX.URL.test(values.portfolio)) {
    errors.portfolio = textualize(
      "onboarding.portfolio.error.invalid",
    ) as string;
  }

  return errors;
};

const PortfolioForm: React.FC<React.PropsWithChildren<IProps>> = ({
  creatorExternalID,
  initialValues,
  onBack,
  onNext,
  onSubmit,
}) => {
  const [videoInteraction, setVideoInteraction] = useState(false);

  const handleSubmit = useCallback(
    async (values: IFormValues) => {
      if (initialValues.portfolio === values.portfolio) {
        onNext();
      } else {
        try {
          await onSubmit(values);

          if (getStepForCreator(creatorExternalID) === 1) {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.ONBOARDING,
              ANALYTICS.EVENT.ONBOARDING_PORTFOLIO,
              {
                videoInteraction,
              },
            );
          }
        } catch {
          // Handled in OnboardingSteps
        }
      }
    },
    [
      creatorExternalID,
      initialValues.portfolio,
      onNext,
      onSubmit,
      videoInteraction,
    ],
  );

  return (
    <ResponsiveLayout>
      <div>
        <StyledSteppedProgress
          aria-label={textualize("onboarding.progress") as string}
          id={IDS.ONBOARDING.PROGRESS}
          step={ONBOARDING_INDICES.PORTFOLIO}
          totalSteps={TOTAL_STEPS}
        />
        <Back id={IDS.ONBOARDING.BACK} onClick={onBack}>
          {textualize("onboarding.back")}
        </Back>

        <Heading>{textualize("onboarding.portfolio.heading")}</Heading>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validation}
        >
          {({ isSubmitting, isValid }: FormikProps<IFormValues>) => (
            <Form id={IDS.ONBOARDING.PORTFOLIO.ID}>
              <Portfolio
                inputProps={{
                  help: textualize("onboarding.portfolio.help") as string,
                  id: IDS.ONBOARDING.PORTFOLIO.INPUT,
                  label: textualize("onboarding.portfolio.label") as string,
                  required: true,
                }}
                name="portfolio"
              />

              <Next
                disabled={isSubmitting || !isValid}
                id={IDS.ONBOARDING.NEXT}
                isLoading={isSubmitting}
                type="submit"
              >
                {textualize("onboarding.next")}
              </Next>
            </Form>
          )}
        </Formik>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.PORTFOLIO.CAPTIONS}
          id={IDS.ONBOARDING.PORTFOLIO.VIDEO}
          onVideoInteraction={() => setVideoInteraction(true)}
          src={VIDEOS.PORTFOLIO.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default PortfolioForm;
