import { css } from "styled-components";

const colors = css`
  --vui-color-primary-light: 103, 80, 164;
  --vui-color-primary-container-light: 255, 255, 255;
  --vui-color-on-primary-light: 255, 255, 255;
  --vui-color-on-primary-container-light: 33, 0, 94;
  --vui-color-inverse-primary-light: 208, 188, 255;
  --vui-color-secondary-light: 98, 91, 113;
  --vui-color-secondary-container-light: 232, 222, 248;
  --vui-color-on-secondary-light: 255, 255, 255;
  --vui-color-on-secondary-container-light: 30, 25, 43;
  --vui-color-tertiary-light: 125, 82, 96;
  --vui-color-tertiary-container-light: 255, 216, 228;
  --vui-color-on-tertiary-light: 255, 255, 255;
  --vui-color-on-tertiary-container-light: 55, 11, 30;
  --vui-color-surface-light: 254, 247, 255;
  --vui-color-surface-dim-light: 222, 216, 225;
  --vui-color-surface-bright-light: 254, 247, 255;
  --vui-color-surface-container-lowest-light: 255, 255, 255;
  --vui-color-surface-container-low-light: 247, 242, 250;
  --vui-color-surface-container-light: 243, 237, 247;
  --vui-color-surface-container-high-light: 236, 230, 240;
  --vui-color-surface-container-highest-light: 230, 224, 233;
  --vui-color-surface-variant-light: 231, 224, 236;
  --vui-color-on-surface-light: 28, 27, 31;
  --vui-color-on-surface-variant-light: 73, 69, 78;
  --vui-color-inverse-surface-light: 49, 48, 51;
  --vui-color-inverse-on-surface-light: 244, 239, 244;
  --vui-color-background-light: 254, 247, 255;
  --vui-color-on-background-light: 28, 27, 31;
  --vui-color-error-light: 179, 38, 30;
  --vui-color-error-container-light: 249, 222, 220;
  --vui-color-on-error-light: 255, 255, 255;
  --vui-color-on-error-container-light: 65, 14, 11;
  --vui-color-outline-light: 121, 116, 126;
  --vui-color-outline-variant-light: 196, 199, 197;
  --vui-color-shadow-light: 0, 0, 0;
  --vui-color-surface-tint-color-light: 103, 80, 164;
  --vui-color-scrim-light: 0, 0, 0;

  --vui-color-primary-dark: 208, 188, 255;
  --vui-color-primary-container-dark: 79, 55, 139;
  --vui-color-on-primary-dark: 55, 30, 115;
  --vui-color-on-primary-container-dark: 234, 221, 255;
  --vui-color-inverse-primary-dark: 103, 80, 164;
  --vui-color-secondary-dark: 204, 194, 220;
  --vui-color-secondary-container-dark: 74, 68, 88;
  --vui-color-on-secondary-dark: 51, 45, 65;
  --vui-color-on-secondary-container-dark: 232, 222, 248;
  --vui-color-tertiary-dark: 239, 184, 200;
  --vui-color-tertiary-container-dark: 99, 59, 72;
  --vui-color-on-tertiary-dark: 73, 37, 50;
  --vui-color-on-tertiary-container-dark: 255, 216, 228;
  --vui-color-surface-dark: 20, 18, 24;
  --vui-color-surface-dim-dark: 20, 18, 24;
  --vui-color-surface-bright-dark: 59, 56, 62;
  --vui-color-surface-container-lowest-dark: 15, 13, 19;
  --vui-color-surface-container-low-dark: 29, 27, 32;
  --vui-color-surface-container-dark: 33, 31, 38;
  --vui-color-surface-container-high-dark: 43, 41, 48;
  --vui-color-surface-container-highest-dark: 54, 52, 59;
  --vui-color-surface-variant-dark: 73, 69, 79;
  --vui-color-on-surface-dark: 230, 225, 229;
  --vui-color-on-surface-variant-dark: 202, 196, 208;
  --vui-color-inverse-surface-dark: 230, 225, 229;
  --vui-color-inverse-on-surface-dark: 49, 48, 51;
  --vui-color-background-dark: 20, 18, 24;
  --vui-color-on-background-dark: 230, 225, 229;
  --vui-color-error-dark: 242, 184, 181;
  --vui-color-error-container-dark: 140, 29, 24;
  --vui-color-on-error-dark: 96, 20, 16;
  --vui-color-on-error-container-dark: 249, 222, 220;
  --vui-color-outline-dark: 147, 143, 153;
  --vui-color-outline-variant-dark: 68, 71, 70;
  --vui-color-shadow-dark: 0, 0, 0;
  --vui-color-surface-tint-color-dark: 208, 188, 255;
  --vui-color-scrim-dark: 0, 0, 0;

  /* Light by default */
  color-scheme: light;

  --vui-color-primary: var(--vui-color-primary-light);
  --vui-color-primary-container: var(--vui-color-primary-container-light);
  --vui-color-on-primary: var(--vui-color-on-primary-light);
  --vui-color-on-primary-container: var(--vui-color-on-primary-container-light);
  --vui-color-inverse-primary: var(--vui-color-inverse-primary-light);
  --vui-color-secondary: var(--vui-color-secondary-light);
  --vui-color-secondary-container: var(--vui-color-secondary-container-light);
  --vui-color-on-secondary: var(--vui-color-on-secondary-light);
  --vui-color-on-secondary-container: var(
    --vui-color-on-secondary-container-light
  );
  --vui-color-tertiary: var(--vui-color-tertiary-light);
  --vui-color-tertiary-container: var(--vui-color-tertiary-container-light);
  --vui-color-on-tertiary: var(--vui-color-on-tertiary-light);
  --vui-color-on-tertiary-container: var(
    --vui-color-on-tertiary-container-light
  );
  --vui-color-surface: var(--vui-color-surface-light);
  --vui-color-surface-dim: var(--vui-color-surface-dim-light);
  --vui-color-surface-bright: var(--vui-color-surface-bright-light);
  --vui-color-surface-container-lowest: var(
    --vui-color-surface-container-lowest-light
  );
  --vui-color-surface-container-low: var(
    --vui-color-surface-container-low-light
  );
  --vui-color-surface-container: var(--vui-color-surface-container-light);
  --vui-color-surface-container-high: var(
    --vui-color-surface-container-high-light
  );
  --vui-color-surface-container-highest: var(
    --vui-color-surface-container-highest-light
  );
  --vui-color-surface-variant: var(--vui-color-surface-variant-light);
  --vui-color-on-surface: var(--vui-color-on-surface-light);
  --vui-color-on-surface-variant: var(--vui-color-on-surface-variant-light);
  --vui-color-inverse-surface: var(--vui-color-inverse-surface-light);
  --vui-color-inverse-on-surface: var(--vui-color-inverse-on-surface-light);
  --vui-color-background: var(--vui-color-background-light);
  --vui-color-on-background: var(--vui-color-on-background-light);
  --vui-color-error: var(--vui-color-error-light);
  --vui-color-error-container: var(--vui-color-error-container-light);
  --vui-color-on-error: var(--vui-color-on-error-light);
  --vui-color-on-error-container: var(--vui-color-on-error-container-light);
  --vui-color-outline: var(--vui-color-outline-light);
  --vui-color-outline-variant: var(--vui-color-outline-variant-light);
  --vui-color-shadow: var(--vui-color-shadow-light);
  --vui-color-surface-tint-color: var(--vui-color-surface-tint-color-light);
  --vui-color-scrim: var(--vui-color-scrim-light);

  /* Add class for dark theme */
  .theme-dark {
    color-scheme: dark;

    --vui-color-primary: var(--vui-color-primary-dark);
    --vui-color-primary-container: var(--vui-color-primary-container-dark);
    --vui-color-on-primary: var(--vui-color-on-primary-dark);
    --vui-color-on-primary-container: var(
      --vui-color-on-primary-container-dark
    );
    --vui-color-inverse-primary: var(--vui-color-inverse-primary-dark);
    --vui-color-secondary: var(--vui-color-secondary-dark);
    --vui-color-secondary-container: var(--vui-color-secondary-container-dark);
    --vui-color-on-secondary: var(--vui-color-on-secondary-dark);
    --vui-color-on-secondary-container: var(
      --vui-color-on-secondary-container-dark
    );
    --vui-color-tertiary: var(--vui-color-tertiary-dark);
    --vui-color-tertiary-container: var(--vui-color-tertiary-container-dark);
    --vui-color-on-tertiary: var(--vui-color-on-tertiary-dark);
    --vui-color-on-tertiary-container: var(
      --vui-color-on-tertiary-container-dark
    );
    --vui-color-surface: var(--vui-color-surface-dark);
    --vui-color-surface-dim: var(--vui-color-surface-dim-dark);
    --vui-color-surface-bright: var(--vui-color-surface-bright-dark);
    --vui-color-surface-container-lowest: var(
      --vui-color-surface-container-lowest-dark
    );
    --vui-color-surface-container-low: var(
      --vui-color-surface-container-low-dark
    );
    --vui-color-surface-container: var(--vui-color-surface-container-dark);
    --vui-color-surface-container-high: var(
      --vui-color-surface-container-high-dark
    );
    --vui-color-surface-container-highest: var(
      --vui-color-surface-container-highest-dark
    );
    --vui-color-surface-variant: var(--vui-color-surface-variant-dark);
    --vui-color-on-surface: var(--vui-color-on-surface-dark);
    --vui-color-on-surface-variant: var(--vui-color-on-surface-variant-dark);
    --vui-color-inverse-surface: var(--vui-color-inverse-surface-dark);
    --vui-color-inverse-on-surface: var(--vui-color-inverse-on-surface-dark);
    --vui-color-background: var(--vui-color-background-dark);
    --vui-color-on-background: var(--vui-color-on-background-dark);
    --vui-color-error: var(--vui-color-error-dark);
    --vui-color-error-container: var(--vui-color-error-container-dark);
    --vui-color-on-error: var(--vui-color-on-error-dark);
    --vui-color-on-error-container: var(--vui-color-on-error-container-dark);
    --vui-color-outline: var(--vui-color-outline-dark);
    --vui-color-outline-variant: var(--vui-color-outline-variant-dark);
    --vui-color-shadow: var(--vui-color-shadow-dark);
    --vui-color-surface-tint-color: var(--vui-color-surface-tint-color-dark);
    --vui-color-scrim: var(--vui-color-scrim-dark);
  }
`;

export default colors;
