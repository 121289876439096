import { SecondaryButton } from "creators/components/Button";
import MobileNavMenu from "creators/components/MobileNavMenu";
import OverlayBackdrop from "creators/components/OverlayBackdrop";
import IDS from "creators/constants/ids";
import HamburgerIcon from "creators/images/icons/hamburger.svg";
import textualize from "creators/utils/textualize";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import Icon from "shared/components/Icon";
import useFeature from "shared/hooks/useFeature";
import VidsyLogo from "shared/images/vidsy-logo.svg";
import styled from "styled-components";

const Container = styled.header`
  align-items: center;
  background: var(--v-color-white);
  display: flex;
  justify-content: space-between;
  padding: 0.625rem var(--v-spacing-4) 0.625rem var(--v-spacing-2);
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledButton = styled(SecondaryButton)`
  background: var(--v-color-white);
  border: none;
  color: var(--v-color-black);
  line-height: 1;
  padding: var(--v-spacing-2);
  width: auto;

  :active {
    border: none;
  }
`;

const StyledIcon = styled(Icon)`
  height: var(--v-icon-size-lg);
  width: var(--v-icon-size-lg);
`;

const StyledLink = styled(Link)`
  line-height: 1;
`;

const StyledVidsyLogo = styled(VidsyLogo)`
  color: var(--v-color-red);
  height: 2rem;
`;

const AnimatedMobileNavMenu = animated(MobileNavMenu);
const AnimatedNavOverlay = animated(OverlayBackdrop);

export const MobileNav = () => {
  const creatorsVUI = useFeature("creatorsVUI");

  const [open, setOpen] = useState(false);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const navMenuTransitions = useTransition(open, {
    enter: { transform: "translateX(0%)" },
    from: {
      transform: "translateX(-100%)",
    },
    leave: { transform: "translateX(-100%)" },
  });

  const navOverlayTransitions = useTransition(open, {
    config: {
      // Hide overlay faster than menu
      clamp: true,
      friction: 13,
    },
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
  });

  if (creatorsVUI) {
    return <>Mobile nav</>;
  }

  return (
    <>
      <Container>
        <StyledButton id={IDS.NAV.MENU_TOGGLE} onClick={openMenu}>
          <StyledIcon
            component={HamburgerIcon}
            label={textualize("nav.open")}
          />
        </StyledButton>

        <StyledLink id={IDS.NAV.VIDSY_LOGO} to="/">
          <StyledVidsyLogo
            aria-label={textualize("common.vidsy")}
            preserveAspectRatio="xMinYMid meet"
          />
        </StyledLink>
      </Container>

      {/* Animate menu in and out when needed */}
      {navMenuTransitions(
        (props, item, { key }) =>
          item && (
            <AnimatedMobileNavMenu
              key={key}
              onClose={closeMenu}
              style={props}
            />
          ),
      )}
      {navOverlayTransitions(
        (props, item, { key }) =>
          item && (
            <AnimatedNavOverlay key={key} onClick={closeMenu} style={props} />
          ),
      )}
    </>
  );
};

export default MobileNav;
