import { css } from "styled-components";

const spacing = css`
  --vui-spacing-0-5: 0.125rem; /* 2px */
  --vui-spacing-1: 0.25rem; /* 4px */
  --vui-spacing-2: 0.5rem; /* 8px */
  --vui-spacing-3: 0.75rem; /* 12px */
  --vui-spacing-4: 1rem; /* 16px */
  --vui-spacing-5: 1.25rem; /* 20px */
  --vui-spacing-6: 1.5rem; /* 24px */
  --vui-spacing-7: 1.75rem; /* 28px */
  --vui-spacing-8: 2rem; /* 32px */
  --vui-spacing-10: 2.5rem; /* 40px */
  --vui-spacing-12: 3rem; /* 48px */
  --vui-spacing-14: 3.5rem; /* 56x */
  --vui-spacing-16: 4rem; /* 64px */
  --vui-spacing-20: 5rem; /* 80px */
  --vui-spacing-24: 6rem; /* 96px */
`;

export default spacing;
