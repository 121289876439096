import { lazy } from "react";
import { IRouteProps } from "shared/types/routes";

const ChangePasswordPage = lazy(() => import("creators/pages/ChangePassword"));
const LoginPage = lazy(() => import("creators/pages/Login"));
const RegisterPage = lazy(() => import("creators/pages/Register"));
const ResetPasswordPage = lazy(() => import("creators/pages/ResetPassword"));

const routes: IRouteProps[] = [
  {
    component: LoginPage,
    exact: true,
    path: "/login",
  },
  {
    component: ResetPasswordPage,
    exact: true,
    path: "/login/reset-password",
  },
  {
    component: ChangePasswordPage,
    exact: true,
    path: "/login/change-password",
  },
  {
    component: RegisterPage,
    exact: true,
    path: "/register",
  },
];

export default routes;
