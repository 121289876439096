import MaintenanceMode from "creators/pages/MaintenanceMode";
import {
  HealthCheckResult,
  HealthCheckType,
  IMaintenanceMode,
} from "creators/types/healthCheck";
import React, { useEffect, useState } from "react";
import useFeature from "shared/hooks/useFeature";

export const HealthCheck: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [failedCheck, setFailedCheck] = useState<HealthCheckResult>();

  const siteStatus = useFeature<IMaintenanceMode>("maintenanceMode");

  useEffect(() => {
    if (siteStatus?.live === false) {
      setFailedCheck({
        details: {
          returnEstimation: siteStatus.returnEstimation,
        },
        type: HealthCheckType.Live,
      });
    } else {
      setFailedCheck(undefined);
    }
  }, [siteStatus]);

  if (failedCheck) {
    return (
      <MaintenanceMode
        returnEstimation={failedCheck.details?.returnEstimation}
      />
    );
  }

  return <>{children}</>;
};

export default HealthCheck;
